import React from 'react';
import VolumeRetrieveEditForm from './VolumeRetrieveEditForm';
import SetTitle from '../../utils/set_title';
import { FilterDataProvider } from '../../HOC/volumeReportFiltersContext';
import { BASE_URL } from '../../utils/index';

const VolumeRetrieveEdit = () => {
SetTitle('Edit Volume Retrieve');
  return (
    <FilterDataProvider>
      <div className="bg-gradient-info secondary-page-title">
        <div md={12} className="form-title">
          <i className="ni ni-time-alarm text-white" style={{ color: 'white', fontSize: '35px' }}></i>
        <h1 className="page-title">
          Edit Volume Retrieve Report
          <a
            href={`${BASE_URL}/docs/ranking_tools/volume-retrieve/`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingLeft: '10px' }}
          >
            <i className="fa-solid fa-question-circle fa-xs text-white"></i>
          </a>
        </h1>
      </div>
    </div>
  <VolumeRetrieveEditForm />
</FilterDataProvider>
)

}

export default VolumeRetrieveEdit