import React, { useEffect, useState, useContext, createContext } from 'react'
import {Spinner} from 'react-bootstrap'
import {
	Col,
	FormGroup,
	Form,
	FormText,
	CardBody,
	Row,
	Button,
	Card,
	CardHeader,
	Input,
	Container
} from "reactstrap";

import { ToastContainer, toast } from 'react-toastify'
import { notify } from '../../utils/notify'
import Email from '../../main/FormFields/Email'
import CharCheckModal from "../../components/molecules/CharCheckModal";
import { api } from '../../utils/index'
import TagsInput from '../../components/TagsInput'

const EmailContext = createContext();

const SemRushForm = () => {
  const [keywords, setKeywords] = useState([])
  const [locations, setLocations] = useState([])
  const [file, setFiles] = useState(null)
  const [emails, setEmails] = useState([])
  const [location, setLocation] = useState('')
  const [loading, setLoading] = useState(false)

	const [endpoint, setEndpoint] = useState('semrush-phrase-these')
	const [displayLimit, setDisplayLimit] = useState(0)
	const [endpoints, setEndpoints] = useState([
		{value: 'semrush-phrase-these', label: 'Semrush - Batch Keyword Overview'},
		{value: 'semrush-related-keywords', label: 'Semrush - Related Keywords'},
		{value: 'semrush-broad-match-keyword', label: 'Semrush - Broad Match Keyword'},
		{value: 'semrush-phrase-questions', label: 'Semrush - Phrase Questions'}
	])
  // Modal check caratteri
  const [showCharCheck, setShowCharCheck] = useState(false)
  const [errCharList, setErrCharList] = useState([])
  const [acceptedCharCheck, setAcceptedCharCheck] = useState(false)

  const handleSubmit = (event) => {
    if (event)
      event.preventDefault()

    let formData = new FormData()
    formData.append('keywords', keywords)
    formData.append('country', location)
    formData.append('emails', emails)
    formData.append('file', file)
	  formData.append('endpoint', endpoint)
		formData.append('display-limit', displayLimit)
    acceptedCharCheck && formData.append('accepted', acceptedCharCheck)

    if((emails == '') || (file==null && keywords == '') || (endpoint !== "semrush-phrase-these" && displayLimit === 0)) {
			notify(
				'Mail, file, valore display limit o keyword mancanti',
				'error'
			  )
			  return
		}

    setLoading(true)
    api
      .post('sem-rush/', formData, {
      })
      .then((response) => {
        if (typeof response?.data === 'undefined') {
          notify(
            'Could not retrieve datas.',
            'success'
          )
          console.warn('Could not retrieve datas')
        }else{
          notify(
            "Report sent to " + emails.join(', '),
            'success'
          )
        }

        setLoading(false)
        setKeywords([])
        setEmails([])
        setFiles(null)
        setEndpoint("semrush-phrase-these")
	      setDisplayLimit(0)
      })
      .catch(err => {
        if (err.response.data?.message.includes('ERROR Encoding keywords')) {
          setErrCharList(err.response.data?.errors);
          setShowCharCheck(true);
        }
        else {
          notify(
            err.response.data,
            'error'
          )
          setLoading(false)
          setKeywords([])
          setEmails([])
          setFiles(null)
          setEndpoint("semrush-phrase-these")
          setDisplayLimit(0)
        }
      })
  }

    const selectedKeywords = keywords => {
    setKeywords(keywords)
  }

  useEffect(() => {
    let locations = ['it', 'fr', 'us', 'de', 'es', 'gb']
    locations = Array.from(new Set(locations))
    setLocations(locations)
    setLocation('it')
    setDisplayLimit('0')
  },[])

  const confirmEvaluation = (e) => {
    e.preventDefault()
    setShowCharCheck(false)
    setAcceptedCharCheck(true)
  }

  useEffect(() => {
    if(acceptedCharCheck) {
      handleSubmit()
    }
  }, [acceptedCharCheck])

    return (
      <>
        <CharCheckModal show={showCharCheck} onHide={setShowCharCheck} onSubmit={confirmEvaluation} errCharList={errCharList}/>
        {loading ? (
          <Spinner animation="grow" />
        ) : (
        <div className="col pb-5">
				<Card className="bg-secondary shadow">
					<CardHeader className="bg-white border-0">
						<Row style={{ marginLeft: "0px" }}>
							<Col md={10}>
								<h1 className="mb-0">SemRush</h1>
							</Col>
						</Row>
					</CardHeader>
          <CardBody>
          <Form onSubmit={handleSubmit} id="idea-form" action="">
            <Row>
              <Col md={6}>
                <FormGroup>
                  <label htmlFor="file" style={{ display: 'block' }}>
                    File
                  </label>
                  <input
                    id={'file'}
                    type={'file'}
                    style={{ border: '0', height: '44px'}}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={event => {
                      setFiles(event.target.files[0])
                    }}
                  />
                  <FormText color="muted" align={'left'}>
                    Carica un file contenente le tue keywords. <br />
                    <b>Il file deve contenere almeno una colonna "Keyword"</b>
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="location" style={{ display: 'block' }}>
                    Location
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="location"
                    type="select"
                    value={location}
                    required
                    style={{ border: '0', height: '44px'}}
                    onChange={event => {
                      setLocation(event.target.value)
                    }}
                  >
                    {locations.map(location => {
                      return (
                        <option key={location} value={location}>
                          {location}
                        </option>
                      )
                    })}
                  </Input>
	                <FormText color="muted" align={'left'}>
										<br/>
									</FormText>
                </FormGroup>
	              <FormGroup required>
									<label htmlFor="endpoints" className="form-control-label">
										Endpoints
									</label>
									<Input
										className="form-control-alternative"
										type="select"
										id="project"
										value={endpoint}
										style={{ border: '0', height: '44px'}}
										onChange={e => setEndpoint(e.target.value)}
										required
									>
										{endpoints.map(m => (
											<option value={m.value} key={m.value}>
												{m.label}
											</option>
										))}
									</Input>
									<FormText color="muted" align={'left'}>
										Seleziona la piattaforma da cui recuperare i dati
									</FormText>
								</FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup required>
                  <label htmlFor="keyword" style={{ display: 'block' }}>
                    Keywords
                  </label>
                  <TagsInput
                    className="form-control-alternative"
										id='keywords'
										selectedTags={selectedKeywords}
										tags={keywords}
										type='text'
                    style={{ border: '0', height: '44px'}}
                  />

                  <FormText color="muted" align={'left'}>
                    Inserisci eventuali keywords aggiuntive. <br />
                    <b>Premi invio dopo ogni nuovo inserimento.</b>
                  </FormText>
                </FormGroup>
	              {(endpoint === "semrush-phrase-these") ? ("") : (
								<FormGroup>
									<label htmlFor="display-limit" className="form-control-label">
										Display Limit
									</label>
									<Input
										className="form-control-alternative"
										id="display-limit"
										type="numeric"
										value={displayLimit}
										onChange={event => {
											setDisplayLimit(event.target.value)
										}}
										style={{ border: '0', height: '44px'}}
									/>
									<FormText color="muted" align={'left'}>
										<b>Inserisci il numero massimo di keyword suggerite per ogni keyword in input</b>
									</FormText>
								</FormGroup>)}
                <FormGroup>
                  <EmailContext.Provider value={{ emails, setEmails }}>
                    <Email context={EmailContext} />
                  </EmailContext.Provider>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{display: "flex", justifyContent: "flex-end", marginRight:"50px"}}>
            <Button color="info" size="lg" onClick={handleSubmit} >Submit Report</Button>
            </Row>          
          </Form>
          </CardBody>
				</Card>
				</div>
        )}
      </>
    )
}

export default SemRushForm
