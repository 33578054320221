import React, { useEffect, useState, useRef, createContext } from 'react'
import { Link } from 'react-router-dom'
import { api } from '../utils/index'
import axios from 'axios'
import { BASE_URL } from '../utils/index'
import { notify } from '../utils/notify'
import TagsInput from '../components/TagsInput'
import auth from '../auth/service'
import {Spinner} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import Email from '../main/FormFields/Email'
import {
  Col,
  FormGroup,
  Form,
  FormText,
  CardBody,
  Row,
  Button,
  Card,
  CardHeader,
  Input,
  Container
} from "reactstrap";

const EmailContext = createContext()

export default function BigQueryExporter() {

  // Parametri fields
  const [userGroup, setUserGroup] = useState('')
  const [projects, setProjects] = useState([''])
  const [project, setProject] = useState('')

  const [dataset, setDataset] = useState('')
  const [datasets, setDatasets] = useState([''])
  const [datasetsLoading, setDatasetsLoading] = useState(false);

  const [table, setTable] = useState('')
  const [tables, setTables] = useState([''])
  const [tablesLoading, setTablesLoading] = useState(false);

  const [period, setPeriod] = useState('')
  const [periods, setPeriods] = useState([''])
  const [periodsLoading, setPeriodsLoading] = useState(false);

  const [emails, setEmails] = useState([])

  const [validated, setValidated] = useState(false);
  
  let axiosCancelToken = useRef();

  const getDatasets = async () => {
    if (project.length > 0) {
      setDatasetsLoading(true)

      //Check if there are any previous pending requests
      if (typeof axiosCancelToken.current != typeof undefined) {
        axiosCancelToken.current.cancel("Operation canceled due to new request.")
      }

      //Save the cancel token for the current request
      axiosCancelToken.current = axios.CancelToken.source()

      api
      .get(`bigquery-exporter/?project=${project}`, { cancelToken: axiosCancelToken.current.token })
      .then(res => {
        setDatasetsLoading(false)
        if (res.data && res.data.results)
          setDatasets(res.data.results)
      })
      .catch(err => {
        setDatasets([])
        setDatasetsLoading(false)
        console.error(err)
      })
    }
  }

  const getTables = () => {
    if (project.length > 0 && dataset.length > 0) {
      setTablesLoading(true)
      api
        .get(`bigquery-exporter/?project=${project}&dataset=${dataset}`)
        .then(res => {
          setTablesLoading(false)
          if (res.data && res.data.results)
            setTables(res.data.results)
        })
        .catch(err => {
          setTablesLoading(false)
          console.error(err)
        })
    }
  }

  const getPeriods = () => {
    if (project.length > 0 && dataset.length > 0 && table.length > 0) {
      setPeriodsLoading(true)
      api
        .get(`bigquery-exporter/?project=${project}&dataset=${dataset}&table_for_period=${table}`)
        .then(res => {
          setPeriodsLoading(false)
          if (res.data && res.data.results)
            setPeriods(res.data.results)
        })
        .catch(err => {
          setPeriodsLoading(false)
          console.error(err)
        })
    }
  }

  const handleClear = () => {
    setProject(projects[0])
    setDataset('')
    setDatasets([])
    setDatasetsLoading(true)
    setTable('')
    setTables([])
    setTablesLoading(true)
    setPeriod('')
    setPeriods([])
    setPeriodsLoading(true)
    setValidated(false)
    setEmails([]);
  }

  const handleSubmit = e => {
    e.preventDefault()

    let fields = {'project': project, 'dataset': dataset, 'table': table, 'email': emails}
    let missing_fields = []
    Object.keys(fields).forEach((el)=>{
      if (!fields[el] || !fields[el].length){
        missing_fields.push(el)
      }
    })
    if(missing_fields.length > 0) {
      let string = 'Campi mancanti: \n' + missing_fields.toString().replaceAll(',', ',\n')
      notify(string,
          'error')
      return
    }

    const form = e.currentTarget
      if (form.checkValidity() === false) {
        e.stopPropagation()
        return
      }
      
    setValidated(true)

    if (project.length > 0 && dataset.length > 0 && table.length > 0 && emails.length > 0) {
      let querystring = new URLSearchParams({project, dataset, table, emails}).toString();
      if (period.length > 0) {
        querystring = new URLSearchParams({project, dataset, table, emails, period}).toString();
      }
      console.log(BASE_URL + '/api/1/bigquery-exporter/?' + querystring)
      api
        .get(BASE_URL + '/api/1/bigquery-exporter/?' + querystring)
        .then(res => {
          notify(
            res.data,
            'success'
          )
        })
        .catch(err => {
          console.error(err)
          notify(
            err.message,
            'error'
          )
        })
    }
  }


  useEffect(() => {
    setValidated(false)
    setTable('')
    setTables([])

    setPeriod('')
    setPeriods([])

    setDataset('')
    getDatasets()
  }, [project])

  useEffect(() => {
    setValidated(false)
    setPeriod('')
    setPeriods([])

    setTable('')
    getTables()
  }, [dataset])

  useEffect(() => {
    setValidated(false)

    setPeriod('')
    getPeriods()
  }, [table])

  useEffect(() => {
    setValidated(false)
  }, [period])

  useEffect(() => {
    if (userGroup == 'LVR') {
      setProjects(['', 'webranking', 'inn-ranking'])
    }
    else {
      setProjects(['', 'webranking', 'inn-ranking', 'w-seo-bigquery', 'w-seo-urls-monitoring',])
    }
  }, [userGroup])

  useEffect(() => {
    auth.getProfile().then(() => {
      setUserGroup(auth.userGroup)
    })
  }, [])

  return (
    <>
    	<Row style={{marginBottom: "100px"}}>
      <div className="col pb-5">
				<Card className="bg-secondary shadow">
						<CardHeader className="bg-white border-0">
							<Row style={{ marginLeft: "0px" }}>
								<Col md={10}>
									<h1 className="mb-0">BigQuery Exporter</h1>
								</Col>
							</Row>
						</CardHeader>
					<CardBody>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup>
              <label htmlFor="project" style={{ display: 'block' }}>
                Progetto
              </label>
              <Input
              	className="form-control-alternative"
                type="select"
                id="project"
                value={project}
                onChange={e => setProject(e.target.value)}
                required
              >
                {projects.map(m => (
                  <option value={m} key={m}>
                    {m}
                  </option>
                ))}
              </Input>
              <FormText color="muted" align={'left'}>
                Progetti per esportazione da BigQuery
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup>
              <label htmlFor="dataset" style={{ display: 'block' }}>
                Dataset
              </label>
              <Input
              	className="form-control-alternative"
                type="select"
                id="dataset"
                value={dataset}
                onChange={e => setDataset(e.target.value)}
                required
                disabled={(datasetsLoading) ? 'disabled' : ''}
              >
                <option value={''} disabled />
                {datasets.length > 0 && datasets.map(m => (
                  <option value={m.dataset_id} key={m.dataset_id}>
                    {m.dataset_id}
                  </option>
                ))}
              </Input>
              <FormText color="muted" align={'left'}>
                Dataset per esportazione da BigQuery
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup>
              <label htmlFor="table" style={{ display: 'block' }}>
                Tabella
              </label>
              <Input
              	className="form-control-alternative"
                type="select"
                id="table"
                value={table}
                onChange={e => setTable(e.target.value)}
                required
                disabled={(tablesLoading) ? 'disabled' : ''}
              >
                <option value={''} disabled />
                {tables.length > 0 && tables.map(m => (
                  <option value={m.table_id} key={m.table_id}>
                    {m.table_id}
                  </option>
                ))}
              </Input>
              <FormText color="muted" align={'left'}>
                Tabella per esportazione da BigQuery
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup>
              <label htmlFor="table" style={{ display: 'block' }}>
                Periodo
              </label>
              <Input
              	className="form-control-alternative"
                type="select"
                id="period"
                value={period}
                onChange={e => setPeriod(e.target.value)}
                disabled={(periodsLoading) ? 'disabled' : ''}
              >
                <option value={''} disabled />
                {periods.length > 0 && periods.map(m => (
                  <option value={m.periodo} key={m.periodo}>
                    {m.periodo}
                  </option>
                ))}
              </Input>
              <FormText color="muted" align={'left'}>
                Periodo di riferimento per esportazione da BigQuery (se non selezionato, vengono scaricati tutti i periodi della tabella)
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup>
              <EmailContext.Provider value={{ emails, setEmails }}>
                <Email context={EmailContext} />
              </EmailContext.Provider>
            </FormGroup>
          </Col>
        </Row>
        
        <Row style={{ marginBottom: '3%', marginTop: '3%' }}>
          <Col xs={12} md={2}>
            <Button style={{ width: '100%' }} onClick={handleClear}>
              Clear
            </Button>
          </Col>
          <Col xs={12} md={4}>
            {(!validated) ? 
							<Button color="info" size="lg" onClick={handleSubmit} >Submit</Button>:
              <Button color="info" variant="secondary" type="submit" disabled>Submit</Button>
            }
          </Col>
        </Row>
      </Form>
      </CardBody>
			</Card>
			</div>
      <ToastContainer />
      </Row>
    </>
  )
}