import React, { Fragment, useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import FormSector from '../../components/FormSector'
import UserHeader from "../../../components/Headers/ReportingHeader.js";
import CharCheckModal from "../../../components/molecules/CharCheckModal";
import {
  ReportName,
  SelectDimensions,
  SelectMetrics,
  ClientName,
  LanguageCode,
  FileSelector,
  SelectDimensionGroups,
  SelectDimensionSet,
} from '../ReportField/ReportField'
import {
  notify,
  loginRedirect, prefix,
} from '../../../utils/index_tagging_tool'
import { api, BASE_URL } from '../../../utils/index'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col
} from "reactstrap";
import _ from 'lodash'
import SetTitle from '../../../utils/set_title'

// Piccolo componentino per quando il report è creato con successo, ha un link alla pagina del report appena creato
function Success({ name, id, back }) {
  return (
    <Fragment>
      <Alert variant="success">
        <Alert.Heading>Configurazione avvenuta</Alert.Heading>
        <p>
          Il tuo report è stato configurato con successo. Puoi raggiungerlo dal
          listing dei report o tramite questo link:
          <br />
          <br />
          <Alert.Link
            style={{ textAlign: 'center', fontSize: '1.5rem' }}
            href={'/reporting/reports/seo/' + id}
          >
            {name}
          </Alert.Link>
        </p>
      </Alert>
    </Fragment>
  )
}

// Componente per il form di creazione di un report, solo per i seo
export default function CreateReport() {
  SetTitle('Tagging tool - Create Report')
  // Booleani per gestire i caricamenti
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false) // questo per visualizzare la schermata di avvenuta creazione

  // Lista usata per controllare che il nome del report non esista già
  const [reports, setReports] = useState([])

  // Info recuperate da db che servono per i selettori del form della creazione
  const [savedDimensions, setSavedDimensions] = useState([])
  const [dimensionGroups, setDimensionGroups] = useState([])
  const [dimensionSets, setDimensionSets] = useState([])
  const [savedMetrics, setSavedMetrics] = useState([])

  // Varie variabili di stato che mantengono le scelte dell'utente e sono infine passate a backend in fase di Creazione
  // Gli stati sono settati dai sottocomponenti contenuti in ReportField
  const [name, setName] = useState('Esselunga')
  const [file, setFile] = useState(null)
  const [client, setClient] = useState('')
  const [languageCode, setLanguageCode] = useState('')
  const [keyword, setKeyword] = useState('')
  const [volume, setVolume] = useState('')
  const [dimensions, setDimensions] = useState([])
  const [groups, setGroups] = useState([])
  const [sets, setSets] = useState([])
  const [metrics, setMetrics] = useState([])
  const [excluded, setExcluded] = useState([]) // OCCHIO: questa è per l'esclusione delle colonne dall'applicazione delle metriche
  // Modal check caratteri
  const [showCharCheck, setShowCharCheck] = useState(false)
  const [errCharList, setErrCharList] = useState([])
  const [acceptedCharCheck, setAcceptedCharCheck] = useState(false)

  // Mantiene l'id del report creato, utile per la pagina di successo
  const [reportId, setReportId] = useState(null)

  // Varie funzioni di fetch per il recupero da db di: report, saved dimensions, saved metrics, set e gruppi
  const fetchReports = () => {
    api.get('tagging-tool/reports/')
      .then(loginRedirect)
      .then((data) => setReports(data.map((x) => x.name.toLowerCase())))
  }

  const fetchSavedDimensions = () => {
    api.get('tagging-tool/saved-dimensions/')
      .then(loginRedirect)
      .then((data) => {
        // Vengono parsati per essere utilizzati da ReactSelect, in più mantengo un valore "gruppo" che mi serve più aventi
        const parsedSaved = data.map((x) => {
          return {
            label: x.name,
            value: x.name,
            group: x.group.name,
          }
        })
        setSavedDimensions(
          _.orderBy(parsedSaved, ['group', 'value'], ['asc', 'asc'])
        )
        setDimensionGroups(
          _.uniqWith(
            // Vengono parsati per essere utilizzati da ReactSelect
            data.map((x) => {
              return { label: x.group.name, value: x.group.name }
            }),
            _.isEqual
          )
        )
      })
  }

  const fetchGroupSets = () => {
    api.get(`tagging-tool/dimension-sets/`)
      .then(loginRedirect)
      .then((data) =>
        setDimensionSets(
          // Vengono parsati per essere utilizzati da ReactSelect
          data.map((s) => {
            return {
              label: s.name,
              value: { groups: s.groups, dims: s.dims },
            }
          })
        )
      )
  }

  const fetchSavedMetrics = () => {
    api.get('/tagging-tool/saved-metrics/')
      .then(loginRedirect)
      .then((data) =>
        setSavedMetrics(
          // Vengono parsati per essere utilizzati da ReactSelect
          data.map((x) => {
            return { label: x.name, value: x.name }
          })
        )
      )
  }

  // Tutti i dati sono fetchati al primo caricamento e mai più, supponendo
  useEffect(() => {
    fetchReports()
    fetchGroupSets()
    fetchSavedDimensions()
    fetchSavedMetrics()
  }, [])

  useEffect(() => {
    // Quando imposto dei set, tengo aggiornate le dimensioni e i gruppi (non sto a mandare il set a backend)
    const groupsCopy = JSON.parse(JSON.stringify(groups))
    const dimensionsCopy = JSON.parse(JSON.stringify(dimensions))
    // Faccio un foreach perchè ogni set imposta i suoi gruppi e dim (dalla select può arrivare più di un set)
    sets.forEach((s) => {
      s.value.groups.forEach((g) => {
        // Controllo che non ci sia già il gruppo prima di aggiungerlo
        if (!groupsCopy.some((obj) => _.isEqual(obj, { label: g, value: g })))
          groupsCopy.push({ label: g, value: g })
      })
      s.value.dims.forEach((dim) => {
        // Controllo che non ci sia già la dimensione prima di aggiungerla
        if (
          !dimensionsCopy.some((obj) =>
            _.isEqual(obj, {
              label: dim.name,
              value: dim.name,
              group: dim.group.name,
            })
          )
        )
          dimensionsCopy.push({
            label: dim.name,
            value: dim.name,
            group: dim.group.name,
          })
      })
      // Setto delle copie perchè risettare la stessa variabile di stato crea casini a volte
      setGroups(groupsCopy)
      setDimensions(dimensionsCopy)
    })
  }, [sets])

  // Autoesplicativa
  const resetForm = () => {
    setName('')
    setFile(null)
    setKeyword('')
    setVolume('')
    setDimensions([])
    setDimensionGroups([])
    setDimensionSets([])
    setMetrics([])
    setExcluded([])
  }

  // Funzione che porta le scelte dell'utente a backend
  const handleSubmit = (e) => {
    if (e)
      e.preventDefault()

    setLoading(true)
    // if (!is_valid) throw Error('Parameters not valid')

    let formData = new FormData()
    formData.append('file', file)
    formData.append('name', name)
    formData.append('client', client)
    formData.append('languageCode', languageCode)
    formData.append(
      'groups',
      groups.map((x) => x.value)
    )
    formData.append(
      'dimensions',
      // Rimuovo il nome del gruppo prima di passare a backend
      JSON.stringify(
        dimensions.map((x) => {
          return {
            name: x.value.split(' (' + x.group + ')')[0],
            group: x.group,
          }
        })
      )
    )
    formData.append(
      'metrics',
      metrics.map((x) => x.value)
    )
    formData.append(
      'excluded',
      excluded.map((x) => x.value)
    )
    acceptedCharCheck && formData.append('accepted', acceptedCharCheck)

    api.post(prefix + 'reports/', formData)
      .then((data) => {
        if (data.status !== 201) {
          throw new Error('Network response was not OK');
        }
        setReportId(data.data)
        notify('Report creato con successo', 'success')
        setSuccess(true)
        setLoading(false)
      })
      .catch((error) => {
        if (error?.response?.data?.message?.includes('ERROR Encoding keywords')) {
          setErrCharList(error.response.data?.errors);
          setShowCharCheck(true);
        }
        else {
          console.log(error.response.data)
          notify(
            error.response.data,
            'error'
          )
          setLoading(false)
        }
      })
  }

  // Possibilità di tornare indietro e creare un altro report una volta nella pagina di successo
  const back = (e) => {
    e.preventDefault()
    resetForm()
    setSuccess(false)
  }

  const confirmEvaluation = (e) => {
    e.preventDefault()
    setShowCharCheck(false)
    setAcceptedCharCheck(true)
  }

  useEffect(() => {
    if(acceptedCharCheck) {
      handleSubmit()
    }
  }, [acceptedCharCheck])

  return (
    <>
    <UserHeader />
    <CharCheckModal show={showCharCheck} onHide={setShowCharCheck} onSubmit={confirmEvaluation} errCharList={errCharList} />
    <Row>
        <Col style={{display: "flex", justifyContent: "center"}}>
          <Card style={{ marginTop: '+3em', maxWidth:'1200px', marginBottom: '100px' }} className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col>
                  <h1>
                    <i className="fa-regular fa-clock fa-sm"/> Add New Report
                    <a href={BASE_URL + '/docs/documentazione_seo/tagging-tool-wiki/'} target="_blank" style={{paddingLeft: "10px"}}>
                      <i className="fa-solid fa-question-circle fa-xs"></i>
                    </a>
                  </h1>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="pt-0 pt-md-4">
              <div id="AddStudiokw">
        <Form onSubmit={handleSubmit}>
          {success ? (
            <Success name={name} id={reportId} back={back} create={true} />
          ) : (
            <div className="Content-box">
              <FormSector label={'Campi obbligatori'} />
              <Row>
                <Col className="col-12 col-md-6">
                  <FileSelector file={file} setFile={setFile} create={true} />
                </Col>
                <Col className="col-12 col-md-6">
                  <ReportName
                    names={reports}
                    name={name}
                    setName={setName}
                    create={true}
                  />
                </Col>
              </Row>
              <div className="mt-5"></div>
              <FormSector label={'Configurazione'} />
              <Row>
                <Col className="col-12 col-md-6">
                  <ClientName
                    client={client}
                    setClient={setClient}
                    create={true}
                  />
                </Col>
                <Col className="col-12 col-md-6">
                  <LanguageCode
                    languageCode={languageCode}
                    setLanguageCode={setLanguageCode}
                    create={true}
                  />
                </Col>
              </Row>
              <div className="mt-5"></div>
              <FormSector label={'Import dimensioni'} />
              <Row>
                <Col className="col-12 col-md-12">
                  <SelectDimensionSet
                    options={dimensionSets}
                    groups={sets}
                    setGroups={setSets}
                    create={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-12 col-md-6">
                  <SelectDimensionGroups
                    options={dimensionGroups}
                    groups={groups}
                    setGroups={setGroups}
                    create={true}
                  />
                </Col>
                <Col className="col-12 col-md-6">
                  <SelectDimensions
                    options={savedDimensions}
                    dimensions={dimensions}
                    setDimensions={setDimensions}
                    excluded={null}
                    setExcluded={null}
                    create={true}
                  />
                </Col>
              </Row>
              <div className="mt-5"></div>
              <FormSector label={'Import metriche'} />
              <Row>
                <Col className="col-12 col-md-6">
                  <SelectMetrics
                    options={savedMetrics}
                    metrics={metrics}
                    setMetrics={setMetrics}
                    create={true}
                  />
                </Col>
                <Col className="col-12 col-md-6">
                  <SelectDimensions
                    options={savedDimensions}
                    dimensions={dimensions}
                    setDimensions={setDimensions}
                    groupDimensions={savedDimensions.filter((s) =>
                      groups.map((g) => g.value).includes(s.group)
                    )}
                    excluded={excluded}
                    setExcluded={setExcluded}
                    create={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <Button
                    type="submit"
                    variant="success"
                    style={{ float: 'right' }}
                  >
                    Crea
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Form>
        </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </>
  )
}
