import React, { useContext } from 'react'
import { Form, FormText, FormGroup, Input } from 'reactstrap'

export default function Nome({ context, isEditable = true }) {
  const { name, setName } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Nome
      </label>
      <Input
        className="form-control-alternative"
        id="name"
        value={name === null ? '' : name}
        onChange={(e) => setName(e.target.value)}
        required={true}
        type="text"
        disabled={!isEditable}
        onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
      />
      <FormText color="muted" align={'left'}>
        Inserisci il <b>nome dello studiokw</b> da creare
      </FormText>
    </FormGroup>
  )
}
