import React, { useEffect, useState, useContext, createContext } from 'react'
import Email from '../../main/FormFields/Email'
import { useHistory } from 'react-router-dom'
import {
    Spinner,
} from 'react-bootstrap'
import {
    Col,
    FormGroup,
    Form,
    FormText,
    CardBody,
    Row,
    Button,
    Card,
    CardHeader,
    Input,
    Container
} from "reactstrap";
import SetTitle from '../../utils/set_title'

import TagsInput from '../../components/TagsInput'
import CharCheckModal from "../../components/molecules/CharCheckModal";
import { api } from '../../utils/index'
import { ToastContainer, toast } from 'react-toastify'
import { notify } from '../../utils/notify'

const EmailContext = createContext()

const VolumeRetrieveForm = () => {
    SetTitle('Volume Retrieve Report')
    const WORLDWIDE = "Worldwide"
    const [locations, setLocations] = useState([])
    const [tables, setTables] = useState([])
    const [file, setFile] = useState(null)
    const [emails, setEmails] = useState([])
    const [province, setProvince] = useState('')
    const [validProvince, setValidProvince] = useState(null)
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const [select, setSelect] = useState('standard',)
    const [schedule, setSchedule] = useState(false)
    const [keywords, setKeywords] = useState([])
    // Modal check caratteri
    const [showCharCheck, setShowCharCheck] = useState(false)
    const [errCharList, setErrCharList] = useState([])
    const [acceptedCharCheck, setAcceptedCharCheck] = useState(false)
    let history = useHistory()
    const [selectedLocations, setSelectedLocations] = useState([]);

    const removeLocation = (locationToRemove) => {
        setSelectedLocations(selectedLocations.filter(location => location !== locationToRemove));
    };

    const handleSubmit = (event) => {
        if (event)
            event.preventDefault()
        let formData = new FormData();
        if (selectedLocations.length > 0) {
            formData.append('locations', selectedLocations.join(','));
        }
        if (province !== '')
            formData.append('province', province) //it will always be empty
        formData.append('emails', emails)
        formData.append('file', file)
        formData.append('select', select)
        formData.append('name', name)
        formData.append('schedule', schedule)
        formData.append('keyword_error_accepted', acceptedCharCheck)

        let missingFields = []
        for (let key of formData.entries()) {
            if (key[1] == '' || key[1] == 'null' || key[1] == null || key[1] == 'null') {
                missingFields.push(key[0])
            }
        }
        if (missingFields.length > 0) {
            //altrimenti la stringa sfora le dimensioni del box
            let string = 'Campi mancanti: \n' + missingFields.toString().replaceAll(',', ',\n')
            notify(
                string,
                'error'
            )
            return
        }

        setLoading(true)
        api
            .post('volume-api/1/volume-retrieve/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            .then((response) => {
                if (typeof response.data !== 'undefined') {
                    notify('Report iniziato correttamente', 'success')
                    setKeywords([])
                    setFile(null)
                    setSelect('standard')
                    setSchedule(false)
                } else {
                    notify(
                        'Richiesta fallita. Qualcosa è andato storto, riprovare o contattare un Innovation',
                        'error'
                    )
                }
                setAcceptedCharCheck(false)
                setLoading(false)
                setFile(null)
            })
            .catch((err) => {
                if (err?.response?.data) {
                    if (err.response.data?.message && err.response.data?.message.includes('ERROR Encoding keywords')) {
                        setErrCharList(err.response.data?.errors);
                        setShowCharCheck(true);
                    }
                    else {
                        notify(
                            err.response.data,
                            'error'
                        )
                        console.log(err.response.data)
                        setLoading(false)
                        setFile(null)
                        setAcceptedCharCheck(false)
                    }
                } else {
                    setLoading(false)
                    setFile(null)
                    setAcceptedCharCheck(false)
                }
            })
    }

    const selectedKeywords = (keywords) => {
        setKeywords(keywords)
    }

    const checkProvince = (e) => {
        e.preventDefault()
        const province = e.target.value.trim()
        setValidProvince(null)
        if (province === '') return // Se la provincia è vuota è inutile controllare
        let formData = new FormData()
        formData.append('province', province)

        api
            .post('check-province/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                if (response.data === true) {
                    setValidProvince(true)
                    if (!selectedLocations.includes(province)) {
                        setSelectedLocations([...selectedLocations, province]);
                        setProvince('');
                    }
                } else
                    setValidProvince(false)
            })
            .catch((err) => {
                setValidProvince(false)
            })
    }

    const confirmEvaluation = (e) => {
        e.preventDefault()
        setShowCharCheck(false)
        setAcceptedCharCheck(true)
    }

    const cancelEvaluation = () => {
        setShowCharCheck(false)
        setAcceptedCharCheck(false)
        setLoading(false)
    }

    useEffect(() => {
        if(acceptedCharCheck) {
            handleSubmit()
        }
    }, [acceptedCharCheck])

    useEffect(() => {
        api
            .get('motori-ricerca/')
            .then((res) => {
                let locations = []
                try {
                    for (let i = 0; i < res.data.length; i++) {
                        locations.push(res.data[i].locale)
                    }
                } catch {
                    console.warn('Error while parsing locations')
                }
                locations.push(WORLDWIDE)
                locations = Array.from(new Set(locations))
                setLocations(locations)
            })
            .catch((err) => console.error(err))

        api
            .get('big-volume-tables/')
            .then((res) => {
                let tables = []
                try {
                    for (let i = 0; i < res.data.length; i++) {
                        tables.push(res.data[i].nome)
                    }
                } catch {
                    console.warn('Error while parsing locations')
                }
                setTables(tables)
            })
            .catch((err) => console.error(err))
    }, [])

    return (
        <>
            <div className="pb-5">
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                        <Row style={{ marginLeft: "0px" }}>
                            <Col md={10}>
                                <h1 className="mb-0">Volume Retrieve Report</h1>
                            </Col>
                            <Col md={2} style={{ textAlign: "right" }}>
                                <Button color="warning" onClick={() => history.push('volume-retrieve')}>&#60;- Go Back</Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <CharCheckModal show={showCharCheck} onHide={cancelEvaluation} onSubmit={confirmEvaluation} errCharList={errCharList} withExclusion={true} />
                        {loading ? (
                            <Spinner animation="grow" />
                        ) : (
                            <Form onSubmit={handleSubmit} id="idea-form" action="">
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <label htmlFor="file" style={{ display: 'block' }}>
                                                File
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id={'file'}
                                                type={'file'}
                                                style={{ border: '0' }}
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                required
                                                onChange={(event) => {
                                                    setFile(event.target.files[0])
                                                }}
                                            />
                                            <FormText color="muted" align={'left'}>
                                                Carica un file contenente le tue keywords. <br />
                                                <b>Il file deve contenere almeno una colonna "Keyword"</b><br />
                                                <b>Le singole keyword non devono contenere questi simboli: @ \ ^ = ! ` {'<'} {'>'} {'['} {']'} {'('} {')'} % | ? ; ~</b>
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <label htmlFor="name" style={{ display: 'block' }}>
                                                Nome
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                type="text"
                                                placeholder="Inserisci nome report"
                                                id="name"
                                                onChange={(event) => {
                                                    if (tables.includes(event.target.value)) {
                                                        setTables(event.target.value)
                                                        setName('')
                                                        document.getElementById('name').value = ''
                                                    } else {
                                                        setName(event.target.value)
                                                        setTables('')
                                                    }

                                                }}
                                                onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
                                            />
                                            <FormText color="muted" align={'left'}>
                                                <b>
                                                    Il nome della tabella su BigQuery sarà uguale al nome
                                                    specificato per il report
                                                </b>
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                             <EmailContext.Provider value={{ emails, setEmails }}>
                                                <Email context={EmailContext} />
                                            </EmailContext.Provider>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <label htmlFor="location" style={{ display: 'block' }}>
                                                Location
                                            </label>
                                            <Input
                                              className="form-control-alternative"
                                              type="select"
                                              id="location"
                                              value={''}
                                              onChange={(event) => {
                                                  if (!selectedLocations.includes(event.target.value)) {
                                                      setSelectedLocations([...selectedLocations, event.target.value])
                                                  }
                                              }}
                                            >
                                                <option value="" disabled hidden>
                                                    Seleziona location
                                                </option>
                                                {locations.map((location, index) => (
                                                  <option key={index} value={location}>
                                                      {location}
                                                  </option>
                                                ))}
                                            </Input>
                                            <div style={{ marginTop: '10px' }}>
                                                {selectedLocations.map((location, index) => (
                                                  <Button
                                                    key={index}
                                                    style={{
                                                        marginRight: '5px',
                                                        marginBottom: '5px',
                                                        borderRadius: '30px',
                                                        padding: '5px 10px',
                                                    }}
                                                    onClick={() => removeLocation(location)}
                                                    color="info"
                                                  >
                                                      {location} &times;
                                                  </Button>
                                                ))}
                                            </div>
                                            <FormText color="muted" align={'left'}>
                                                Se vengono selezionate più località, verrà generato
                                                un unico report che include tutte le località selezionate.
                                                <br />
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <label htmlFor="province" style={{ display: 'block' }}>
                                                Province
                                            </label>
                                      <Input
                                        className="form-control-alternative"
                                                id="province"
                                                type='text'
                                                style={{ height: '44px' }}
                                                onChange={(event) => {
                                                    setProvince(event.target.value)
                                                }}
                                                onBlur={(event) => checkProvince(event)}
                                                onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
                                            />
                                            <FormText color="muted" align={'left'}>
                                                {validProvince === true && (
                                                    <b style={{ color: 'green' }}>
                                                        La provincia è valida e aggiunta all'elenco
                                                        <br />
                                                    </b>
                                                )}
                                                {validProvince === false && (
                                                    <b style={{ color: 'red' }}>
                                                        La provincia non è valida
                                                        <br />
                                                    </b>
                                                )}
                                                <strong>Campo opzionale</strong>, consente di richiedere i
                                                volumi di una specifica provincia. Inserire il nome della
                                                provincia per esteso (E.s. "Correggio")
                                                <br />
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: "25px" }}>
                                    <Col md={6}>
                                        <FormGroup>
                                            <label htmlFor="schedule">
                                                Monthly update
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="schedule"
                                                type="checkbox"
                                                style={{ width: 'auto' }}
                                                onClick={() =>
                                                    setSchedule(!schedule)
                                                }
                                            />
                                            <FormText color="muted">
                                                Se attivo,{' '}
                                                <b>la tabella di BigQuery verrà aggiornata mensilmente</b>{' '}
                                                tramite Api Adwords.
                                                <br />
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ display: "flex", justifyContent: "flex-end", marginRight: "50px" }}>
                                    <Button color="info" size="lg" onClick={handleSubmit}>Submit Report</Button>
                                </Row>
                            </Form>
                        )}
                        <ToastContainer />
                    </CardBody>
                </Card>
            </div>
        </>
    )
}
export default VolumeRetrieveForm
